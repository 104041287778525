import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import { CancelPresentationRounded } from '@mui/icons-material';
import CanvasJSReact from '../../../ui/canvasjs/canvasjs.react';
import PropTypes from 'prop-types';
import ModalDialog from '../../ModalDialog';
import styles from './styles';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import { HYDRAULICS_CHART_COLORS } from '../../../../shared/config/hydraulics_constants';
import { getOptimalInterval } from '../../../../utils/getOptimalInterval';
import { useSelector } from 'react-redux';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

function DepthVsDensityChartDialog({ onCloseClick, isOpen, data }) {
  const classes = styles();
  const chartData = [...data.run_response.depth_points];
  const summaryData = data.run_response.summary;
  const window = useSelector((state) => state.wellsWindow);

  const [chartConfig, setChartConfig] = useState([
    {
      name: 'ESD',
      key: 'esd',
      color: HYDRAULICS_CHART_COLORS.ESD,
      hide: false,
      tooltip: 'MD: {y} ft <br/> ESD: {x} ppg'
    },
    {
      name: 'ESD with SBP',
      key: 'esd_with_sbp',
      color: HYDRAULICS_CHART_COLORS.ESD_WITH_SBP,
      hide: false,
      tooltip: 'MD: {y} ft <br/> ESD: {x} ppg'
    },
    {
      name: 'ECD',
      key: 'ecd',
      color: HYDRAULICS_CHART_COLORS.ECD,
      hide: false,
      tooltip: 'MD: {y} ft <br/> ECD: {x} ppg <br/> Annular Pressure: {ann_p} psi <br/> Static Well SBP: {static_sbp} psi'
    },
    {
      name: 'ECD with SBP',
      key: 'ecd_with_sbp',
      color: HYDRAULICS_CHART_COLORS.ECD_WITH_SBP,
      hide: false,
      tooltip: 'MD: {y} ft <br/> ECD: {x} ppg <br/> Annular Pressure: {ann_p_with_sbp} psi <br/> Static Well SBP: {static_sbp_with_sbp} psi'
    },
    {
      name: 'PP',
      key: 'pp',
      color: HYDRAULICS_CHART_COLORS.PORE_PRESSURE,
      hide: false,
      tooltip: 'MD: {y} ft <br/> PP: {x} ppg'
    },
    {
      name: 'FP',
      key: 'fp',
      color: HYDRAULICS_CHART_COLORS.FRACTURE_PRESSURE,
      hide: false,
      tooltip: 'MD: {y} ft <br/> FP: {x} ppg'
    },
  ]);

  const generateWindowDataPoints = (config) => {
    const dataPoints = [];

    if (window?.data.length === 1) {
      const singleDataPoint = window?.data[0];
      dataPoints.push(
        { x: singleDataPoint[config.key], y: 0, markerType: 'none' },
        { x: singleDataPoint[config.key], y: singleDataPoint.md },
        { x: singleDataPoint[config.key], y: chartData.at(-1).md, markerType: 'none' }
      );
    } else if (window?.data.length > 1) {
      const firstPoint = window?.data[0];
      const lastPoint = window?.data.at(-1);

      if (firstPoint.md > 0) {
        dataPoints.push({ x: firstPoint[config.key], y: 0, markerType: 'none' });
      }

      window?.data.forEach(item => {
        dataPoints.push({ x: item[config.key], y: item.md });
      });

      dataPoints.push({ x: lastPoint[config.key], y: chartData.at(-1).md, markerType: 'none' });
    }

    return dataPoints;
  };

  const generateViewportMinMax = () => {
    const minMaxData = [...chartData, ...window.data];
    const keysToCheck = ['esd', 'ecd', 'esd_with_sbp', 'ecd_with_sbp', 'pp', 'fp'];

    const allValues = [];

    // Collect all values from the specified keys
    keysToCheck.forEach(key => {
      minMaxData.forEach(obj => {
        if (key in obj) {
          allValues.push(obj[key]);
        }
      });
    });

    // Calculate the overall lowest and highest values
    const overallLowest = Math.min(...allValues);
    const overallHighest = Math.max(...allValues);

    const results = {
      lowest: overallLowest,
      highest: overallHighest,
    };

    return results;
  };

  const checkEquality = (objects) => {
    return {
      ecdEqual: objects.every(obj => obj.ecd === obj.ecd_with_sbp),
      esdEqual: objects.every(obj => obj.esd === obj.esd_with_sbp),
    };
  };

  useEffect(() => {
    const { ecdEqual, esdEqual } = checkEquality(chartData);
    const hasWindowData = window?.data?.length > 0;

    setChartConfig(prevData =>
      prevData.filter(item => {
        const removeLegend =
          (item.key === 'ecd_with_sbp' && ecdEqual) ||
          (item.key === 'esd_with_sbp' && esdEqual);
        const shouldRemoveWindowKeys = !hasWindowData && (item.key === 'pp' || item.key === 'fp');
        return !removeLegend && !shouldRemoveWindowKeys;
      })
    );
  }, []);

  const CustomLegend = () => {
    const updateHide = (key, hideValue) => {
      setChartConfig(prevItems =>
        prevItems.map(item =>
          item.key === key ? { ...item, hide: hideValue } : item
        )
      );
    };

    return (
      <Grid container>
        {chartConfig.map((item) => (
          <Box
           key={item.name}
           className={classes.legendItem}
           sx={{ opacity: item.hide ? 0.4 : 1 }}
           onClick={() => {
             updateHide(item.key, !item.hide);
           }}
           >
            <LinearScaleIcon fontSize='large' sx={{ color: item.color, mr: 1 }} />
            {item.name}
          </Box>
        ))}
      </Grid>
    );
  };

  const options = {
    theme: 'light2',
    animationEnabled: true,
    zoomEnabled: true,
    exportEnabled: true,
    zoomType: 'y',
    toolbar: {
      itemBackgroundColor: '#d3d3d3',
      itemBackgroundColorOnHover: '#3e3e3e',
      buttonBorderColor: '#3e3e3e',
    },
    backgroundColor: '#FFF',
    axisX: {
      viewportMinimum: generateViewportMinMax()?.lowest - 0.1,
      viewportMaximum: generateViewportMinMax()?.highest + 0.1,
      interval: getOptimalInterval(summaryData.bh_esd, summaryData.bh_ecd, 5),
      valueFormatString: '0.0',
      labelFontSize: 16,
      gridThickness: 1,
      title: 'EMW (ppg)',
      titleFontSize: 22,
    },
    axisY: {
      viewportMinimum: null,
      viewportMaximum: null,
      title: 'MD (ft)',
      titleFontSize: 22,
      labelFontSize: 16,
      includeZero: false,
      valueFormatString: '0',
      reversed: true,
      labelPlacement: 'outside',
      gridThickness: 1,
    },
    height: 800,
    legend: {
      fontSize: 20
    }
  };

  const getModalTitle = () => {
    return (
      <>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
            {`${data?.name}`}
          </Grid>
          <Grid item>
            <CustomLegend />
          </Grid>
          <Grid item>
          <IconButton
              sx={{ mr: 2 }}
              onClick={() => {
                onCloseClick();
              }}
            >
              <CancelPresentationRounded fontSize='large'/>
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
  <ModalDialog
    title={getModalTitle()}
    isOpen={isOpen}
    onClose={onCloseClick}
    dialogClass={classes.dialogModal}
  >
    <Grid
     sx={{
       p: 3,
       pt: 4,
       '.canvasjs-chart-toolbar': {
         top: '-30px !important',
       },
     }}
    >
    <CanvasJSChart
        options={{
          ...options,
          data: chartConfig.map((config) => {
            if (config.hide) return {};
            if (config.key === 'pp' || config.key === 'fp') {
              return {
                type: 'line',
                name: config.name,
                showInLegend: false,
                toolTipContent: config.tooltip,
                dataPoints: generateWindowDataPoints(config),
                color: config.color,
              };
            }
            return {
              type: 'line',
              name: config.name,
              showInLegend: false,
              toolTipContent: config.tooltip,
              dataPoints: chartData.map(item => ({
                x: item[config.key],
                y: item.md,
                ann_p: item?.ann_p,
                static_sbp: item?.static_sbp,
                ann_p_with_sbp: item?.ann_p_with_sbp,
                static_sbp_with_sbp: item?.static_sbp_with_sbp
              })),
              color: config.color,
            };
          })
        }}
      />
    </Grid>
  </ModalDialog>
  );
}

DepthVsDensityChartDialog.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  data: PropTypes.object,
  isOpen: PropTypes.bool.isRequired
};

export default DepthVsDensityChartDialog;
