import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardMedia, Typography, Avatar } from '@mui/material';
import { useTheme } from '@mui/styles';
import styles from './styles';
import RigsDetailedPhotoCard from '../rigDetailedPhotoCard';

const RigPhotoCard = ({
  avatarLetter = 'A',
  title,
  comment,
  image,
  avatarColor = 'green',
  email,
}) => {
  const theme = useTheme();
  const classes = styles();
  const [detailedView, setDetailedView] = useState({
    open: false,
    data: {}
  });

  return (
    <>
      <Card className={classes.root} onClick={() => {
        setDetailedView({
          open: true,
          data: {
            avatarLetter,
            avatarColor,
            title,
            comment,
            image,
            email
          }
        });
      }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: avatarColor }} aria-label="recipe">
              {avatarLetter}
            </Avatar>
          }
          title={title}
          subheader={email}
          titleTypographyProps={{ fontSize: 16, fontWeight: 'bold', noWrap: true, align: 'left' }}
          subheaderTypographyProps={{ align: 'left', fontSize: 14 }}
        />
        <CardMedia
          component="img"
          height="250px"
          image={image}
          alt={title}
          sx={{ objectFit: 'contain', p: theme.spacing(1) }}
        />
        <Typography
          variant="body2"
          color="text.primary"
          className={classes.comment}
        >
          {comment}
        </Typography>
      </Card>
      {detailedView?.open && (
      <RigsDetailedPhotoCard
      open={detailedView?.open}
      handleClose={() => {
        setDetailedView({
          open: false,
          data: {}
        });
      }}
      data={detailedView?.data}
      />
      )}
    </>
  );
};

RigPhotoCard.propTypes = {
  avatarLetter: PropTypes.string,
  title: PropTypes.string.isRequired,
  comment: PropTypes.string,
  image: PropTypes.string.isRequired,
  footer: PropTypes.string.isRequired,
  avatarColor: PropTypes.string,
  email: PropTypes.string,
};

export default RigPhotoCard;
