/* eslint-disable camelcase */
import React from 'react';
import ModalDialog from '../../ModalDialog';
import styles from './styles';
import { Box, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import { CancelPresentationRounded } from '@mui/icons-material';
import CanvasJSReact from '../../canvasjs/canvasjs.react';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const PumpRampModel = ({
  onCloseClick,
  isOpen,
  pumpRamps
}) => {
  const classes = styles();

  const options = {
    animationEnabled: true,
    theme: 'light2',
    axisY: {
      title: 'SBP (psi)',
      includeZero: true,
    },
    axisY2: {
      title: 'Flow In (gpm)',
    },
    axisX: {
      title: 'Steps',
    },
    data: [
      {
        type: 'column',
        showInLegend: true,
        name: 'Flow In (gpm)',
        color: '#c0d0e5',
        toolTipContent: 'Flow In: {y} gpm',
        axisYType: 'secondary',
        dataPoints: pumpRamps?.row?.run_response?.pump_ramps.map((dataPoint) => ({
          label: 'Flow In',
          y: dataPoint.flow_in,
        })),
      },
      {
        type: 'line',
        name: 'SBP (psi)',
        showInLegend: true,
        color: '#1D3956',
        axisYType: 'primary',
        toolTipContent: 'SBP: {y} psi',
        dataPoints: pumpRamps?.row?.run_response?.pump_ramps.map((dataPoint, index) => ({
          label: `${index + 1}`,
          y: dataPoint.sbp,
        })),
      },
    ],
  };

  const getModalTitle = () => {
    return (
      <>
      <Grid container alignItems="center" justifyContent="space-between">
      <Grid item>
            {`${pumpRamps?.row?.name}`}
          </Grid>
          <Grid item>
          {'Ramp Schedule'}
          </Grid>
          <Grid item>
          <IconButton
              onClick={() => {
                onCloseClick();
              }}
            >
              <CancelPresentationRounded fontSize='large'/>
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <ModalDialog
    isOpen={isOpen}
    title={getModalTitle()}
    onClose={onCloseClick}
    dialogClass={classes.dialogModal}
    maxWidth='md'
  >
    <Grid item container>
        <Box style={{ padding: '5%' }}>
        <TableContainer component={Paper} className={classes.tableContainer}>
      <Table sx={{ borderCollapse: 'collapse' }}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.headerCell} align="center">Flow In (gpm)</TableCell>
            <TableCell className={classes.headerCell} align="center">SBP (psi)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pumpRamps?.row?.run_response?.pump_ramps.map((row, index) => (
            <TableRow key={index}>
              <TableCell className={classes.rowCell} align="center">{row.flow_in}</TableCell>
              <TableCell className={classes.rowCell} align="center">{row.sbp}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        </Box>
        <Box style={{ padding: '5%' }}>
        <CanvasJSChart options={options} />
        </Box>
    </Grid>
  </ModalDialog>
  );
};
PumpRampModel.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  pumpRamps: PropTypes.any
};
PumpRampModel.defaultProps = {
  selectedJob: {}
};

export default PumpRampModel;
