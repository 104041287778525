const masterColumn = () => [
  {
    field: 'RigName_Number',
    headerName: 'Rig Name',
    width: 200,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'class',
    headerName: 'Class',
    width: 200,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'division',
    headerName: 'Division',
    width: 200,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'LastCheckIn',
    headerName: 'Last Check In',
    width: 210,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'MPESpend',
    headerName: 'MPE Spend',
    width: 210,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
    valueFormatter: (params) => `$${params.value}`
  },
  {
    field: 'NonMPESpend',
    headerName: 'Non-MPE Spend',
    width: 210,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
    valueFormatter: (params) => `$${params.value}`
  },
];

export { masterColumn };
