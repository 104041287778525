/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import styles from './styles';
import { Box, Dialog, Divider, Grid, IconButton, Typography } from '@mui/material';
import Datagrid from '../../Datagrid';
import PropTypes from 'prop-types';
import { CancelPresentationRounded, DeleteRounded, SaveRounded } from '@mui/icons-material';
import columns from './table_config';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from 'react-redux';
import SelectEditInputTextField from '../../SelectEditInputTextField';
import { createMudPumps, deleteMudPumps, updateMudPumps } from '../../../../store/actions/hydraulics';
import { enqueueSnackbar } from '../../../../store/actions/snackbar';
import SelectEditValueTypes from '../../SelectEditValueTypes';

function generateRandom() {
  const length = 24;
  const charset = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

const MudPumpModel = ({
  onCloseClick,
  selectedJob,
  isOpen,
}) => {
  const classes = styles();
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();
  const { mudPumps } = useSelector((state) => state.hydraulics);
  const [properties, setProperties] = useState([]);
  const [invalidRows, setInvalidRows] = useState({});
  const [isOutputEditable, setIsOutputEditable] = useState(true);

  useEffect(() => {
    if (!mudPumps.loading) {
      setProperties(mudPumps.data);
    }
    return () => {
      setProperties([]);
    };
  }, [mudPumps.data]);

  useEffect(() => {
    if (properties.length > 0) {
      const initialRow = properties[0];
      const allFieldsZero = !checkInputs(initialRow);
      setIsOutputEditable(allFieldsZero);
    }
  }, [properties]);

  useEffect(() => {
    if (properties[0]?.liner_size === '') {
      const id = properties[0]?._id;
      setTimeout(() => {
        apiRef.current.scrollToIndexes({
          rowIndex: 1
        });
        apiRef.current.setCellMode(id, 'liner_size', 'edit');
        setTimeout(() => {
          apiRef.current.setCellFocus(id, 'liner_size');
        }, 50);
      }, 50);
      apiRef.current.setPage(0);
    }
  }, [properties.length]);

  const handleAddProperty = () => {
    const currentProperties = [...properties];
    const nextId = currentProperties.length - 1;
    const newRow = {
      pumpType: 'Triplex',
      liner_size: '',
      stroke_length: '',
      efficiency: '',
      bbl_stk: '',
      grid_id: nextId + 1,
      _id: generateRandom(),
      isNewRow: true
    };
    currentProperties.unshift(newRow);
    setProperties(currentProperties);
  };

  const calculateBBLStk = (row) => {
    const { liner_size = 0, stroke_length = 0, efficiency = 0, pumpType } = row;
    let bbl_stk = (0.000243 * Math.pow(liner_size, 2) * stroke_length) * (efficiency / 100);

    if (pumpType === 'Quad') {
      bbl_stk *= 4 / 3;
    }

    return bbl_stk.toFixed(6);
  };

  const checkInputs = (row) => {
    return row.liner_size > 0 || row.stroke_length > 0 || row.efficiency > 0;
  };

  const updateData = (id, field, value) => {
    const numericValue = Number(value);
    let errorMessage = '';

    if (field === 'liner_size' && (isNaN(numericValue) || numericValue < 0 || numericValue > 12)) {
      errorMessage = 'Liner size should be a number between 0 and 12';
    } else if (field === 'stroke_length' && (isNaN(numericValue) || numericValue < 0 || numericValue > 50)) {
      errorMessage = 'Stroke length should be a number between 0 and 50';
    } else if (field === 'efficiency' && (isNaN(numericValue) || numericValue < 0 || numericValue > 100)) {
      errorMessage = 'Efficiency should be a number between 0 and 100';
    }

    if (errorMessage) {
      setInvalidRows(prev => ({ ...prev, [id]: true }));
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
    } else {
      setInvalidRows(prev => ({ ...prev, [id]: false }));
      setProperties(prevProperties => {
        const updatedProperties = prevProperties.map(item => {
          if (item._id === id) {
            const updatedItem = { ...item, [field]: value };

            if (field === 'liner_size' || field === 'stroke_length' || field === 'efficiency' || field === 'pumpType') {
              updatedItem.bbl_stk = calculateBBLStk(updatedItem);
            }

            return updatedItem;
          }
          return item;
        });
        return updatedProperties;
      });
    }
    const updatedRow = properties.find(item => item._id === id);
    setIsOutputEditable(!checkInputs(updatedRow));
  };

  const checkMudPumpValues = (row) => {
    const invalidFields = [];
    if (!row.bbl_stk || Number(row.bbl_stk) <= 0) {
      if (!row.liner_size && !row.stroke_length && !row.efficiency) {
        setInvalidRows(prev => ({ ...prev, [row._id]: true }));
        dispatch(
          enqueueSnackbar(
            'Output (bbl/stk) must be greater than zero.',
            'error',
            new Date().getTime() + Math.random()
          )
        );
        return false;
      }

      if (!row.liner_size || isNaN(Number(row.liner_size))) invalidFields.push('Liner Size');
      if (!row.stroke_length || isNaN(Number(row.stroke_length))) invalidFields.push('Stroke Length');
      if (!row.efficiency || isNaN(Number(row.efficiency))) invalidFields.push('Efficiency');
    }
    if (invalidFields.length === 0) {
      setInvalidRows(prev => ({ ...prev, [row._id]: false }));
      return true;
    } else {
      setInvalidRows(prev => ({ ...prev, [row._id]: true }));
      dispatch(
        enqueueSnackbar(
          `Please enter the following details: ${invalidFields.join(', ')}`,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return false;
    }
  };

  const saveProperty = (row) => {
    if (row.bbl_stk <= 0) {
      dispatch(
        enqueueSnackbar(
          'Pump output should be greater than zero.',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return;
    }

    if (checkMudPumpValues(row)) {
      if (row?.isNewRow) {
        const payload = {
          pumpType: row.pumpType,
          liner_size: Number(row.liner_size),
          stroke_length: Number(row.stroke_length),
          efficiency: Number(row.efficiency),
          bbl_stk: Number(row.bbl_stk),
          grid_id: row.grid_id
        };
        dispatch(createMudPumps(payload, selectedJob?.wellsInfoId, selectedJob?._id));
      } else {
        dispatch(updateMudPumps(row));
      }
    }
  };

  const handleDelete = (row) => {
    dispatch(deleteMudPumps(row?._id, row?.jobId));
  };

  const renderValueCell = (params) => {
    return (
      <SelectEditInputTextField
        numberFormatOnly
        {...params}
        updateData={updateData}
      />
    );
  };

  const renderActionCell = ({ row }) => {
    const isRowInvalid = invalidRows[row._id];
    return (
      <Grid container justifyContent="center">
       <IconButton
          variant="solid"
          disabled= {isRowInvalid}
          onClick={() => {
            saveProperty(row);
          } }
        >
          <SaveRounded />
        </IconButton>
        <IconButton
          variant="solid"
          disabled={row?.isNewRow}
          onClick={() => {
            handleDelete(row);
          }}
        >
            <DeleteRounded />
        </IconButton>
      </Grid>
    );
  };

  const renderTypeCell = (params) => {
    return (
      <SelectEditValueTypes
        {...params}
        closeCellAfterSelect={true}
        updateData={updateData}
        data={['Triplex', 'Quad']}
      />
    );
  };

  return (
    <Dialog
    sx={{
      marginLeft: '-1%'
    }}
    open={isOpen}
    onClose={onCloseClick}
    maxWidth={'lg'}
    fullWidth={true}
  >
        <div className={classes.dialogTitle}>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.3rem' }}>
      <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            {'Mud Pumps'}
          </Grid>
          <Grid item>
          <IconButton
              onClick={() => {
                onCloseClick();
              }}
            >
              <CancelPresentationRounded fontSize='large'/>
            </IconButton>
          </Grid>
        </Grid>
      </Typography>
    </div>
    <Divider />
    <Grid container>
    <Grid item className={classes.customTool} xs={1} justifyContent="space-between" >
           <Box>
              <Typography
                variant='button'
                fontWeight='700'
                className={mudPumps.loading || properties.length > 0 ? classes.disableIcon : ''}
                onClick={properties.length > 0 ? null : handleAddProperty}>
                + Add
              </Typography>
            </Box>
        </Grid>
      <Grid item xs={12}>
      <Datagrid
            apiRef={apiRef}
            data={properties}
            loading={mudPumps.loading}
            getRowId={(row) => row._id}
            columns={columns(renderValueCell, renderActionCell, renderTypeCell, isOutputEditable)}
            disableStickyHeader={false}
            autoHeight={false}
            height={'50vh'}
            sx={{
              '.MuiDataGrid-cell': {
                fontSize: '1rem',
                fontWeight: 900
              },
              '.MuiDataGrid-columnHeader': {
                fontSize: '1.2rem',
                fontWeight: '900 !important'
              }
            }}
            getRowClassName={(params) => {
              return invalidRows[params.id] ? classes.invalidRow : '';
            }}
            pageSize={100}
            editMode="cell"
            toolbar={{
              options: {
                columns: false,
                filters: false,
                density: false,
                export: false
              },
            }}
          />
      </Grid>
    </Grid>
  </Dialog>
  );
};
MudPumpModel.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  selectedJob: PropTypes.object,
  isOpen: PropTypes.bool.isRequired
};
MudPumpModel.defaultProps = {
  selectedJob: {}
};

export default MudPumpModel;
