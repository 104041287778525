import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import proGeoBlueIcon from '../../assets/images/pro_geo_blue.png';
import pinRed from '../../assets/images/pin_red.png';
import Datagrid from '../ui/Datagrid';
import { masterColumn } from './table_config';
import { Grid, IconButton, TablePagination } from '@mui/material';
import PropTypes from 'prop-types';
import { MAP_TYPES, REDUX_ACTIONS } from '../../shared/config/constants';
import { Icon } from 'leaflet';
import Map from '../map';
import { listRigs } from '../../store/actions/rigs';
import styles from './styles';
import { listCheckInsDrill, listCheckInsRigs } from '../../store/actions/checkInsDrill';
import { CameraAlt } from '@mui/icons-material';
import RigsPhotosModal from './rigPhotosModal';

const pins = {
  checkInsIcon: proGeoBlueIcon,
  rigIcon: pinRed
};

// eslint-disable-next-line react/prop-types
function CustomPagination({ currentPage, limit, totalCount, handlePagination, loading }) {
  return (
    <TablePagination
      component="div"
      count={totalCount}
      page={currentPage}
      onPageChange={(event, page) => !loading && handlePagination(page)}
      rowsPerPage={limit}
      rowsPerPageOptions={[]}
    />
  );
}

const CheckInsDrill = () => {
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const checkInsDrill = useSelector((state) => state.checkInsDrill);
  const rigs = useSelector((state) => state.rigs);
  const user = useSelector((state) => state.currentUser);
  const mapRef = useRef(null);
  const [filter, setFilter] = useState([]);
  const mapType = process.env.REACT_APP_CHECKINS_DRILL_PAGE_MAP;
  const [rowId, setRowId] = useState(null);
  const [gmapRowId, setGmapRowId] = useState(null);
  const [iconPinSize, setIconPinSize] = useState([15, 25]);
  const [isRigPhotosOpen, setIsRigPhotosOpen] = useState(false);
  const classes = styles();

  useEffect(() => {
    if (!mapRef?.current?.invalidateSize) return;
    mapRef.current.invalidateSize();
  }, [user.preferences.aspectRatioPage]);

  useEffect(() => {
    if (filter.length === 0 && checkInsDrill.data.length) {
      dispatch(listCheckInsDrill());
    }
  }, [filter]);

  useEffect(() => {
    if (!checkInsDrill.loading && checkInsDrill.data.length === 0) {
      dispatch({
        type: REDUX_ACTIONS.CHECKINS_DETAILS_LOADING,
      });
      dispatch(listCheckInsDrill());
    }
    if (!rigs.loading && rigs.data.length === 0) {
      dispatch({
        type: REDUX_ACTIONS.RIGS_LIST_LOADING,
      });
      dispatch(listRigs());
    }
  }, []);

  const getMapPin = () => {
    const pinColor = 'checkInsIcon';
    const pinIcon = pinColor ? pins[pinColor] : pins.default;

    return (
      pinIcon &&
      new Icon({
        iconUrl: pinIcon,
        iconSize: iconPinSize,
        popupAnchor: [0, -5],
      })
    );
  };

  const getMapPin2 = () => {
    const pinColor = 'rigIcon';
    const pinIcon = pinColor ? pins[pinColor] : pins.default;

    return (
      pinIcon &&
      new Icon({
        iconUrl: pinIcon,
        iconSize: iconPinSize,
        popupAnchor: [0, -5],
        className: classes.rigIconPopup,
      })
    );
  };

  const getPinColor = (status) => {
    switch (status) {
      default:
        return 'red';
    }
  };

  const getGoogleMapPin = (status) => {
    const pinColor = getPinColor(status);
    const pinIcon = pinColor ? pins[pinColor] : pins.default;
    return {
      url: pinIcon,
      // eslint-disable-next-line no-undef
      scaledSize: new google.maps.Size(iconPinSize[0], iconPinSize[1]),
      // eslint-disable-next-line no-undef
      size: new google.maps.Size(iconPinSize[0] + 10, iconPinSize[1] + 10)
    };
  };

  const handlePagination = (page) => {
    if (page < checkInsDrill.currentPage) {
      dispatch(listCheckInsDrill({ page: checkInsDrill.currentPage - 1 }));
      dispatch({
        type: REDUX_ACTIONS.CHECKINS_DRILL_SET_CURRENT_PAGE,
        payload: checkInsDrill.currentPage - 1
      });
    } else {
      dispatch(listCheckInsDrill({ page: checkInsDrill.currentPage + 1 }));
      dispatch({
        type: REDUX_ACTIONS.CHECKINS_DRILL_SET_CURRENT_PAGE,
        payload: page + 1
      });
    }
    apiRef.current.setFilterModel({
      items: filter
    });
    apiRef.current.scrollToIndexes({
      rowIndex: 0,
      colIndex: 0,
    });
  };

  const handleMarkerClick = (data) => {
    const row = checkInsDrill?.data.find((row) => row.grid_id === data.grid_id);
    if (row) {
      setRowId(row);
      const rowIndex = checkInsDrill.data.findIndex((r) => r.grid_id === data.grid_id);
      if (rowIndex !== -1) {
        apiRef.current.scrollToIndexes({
          rowIndex,
          colIndex: 0,
        });
      }
    }
  };

  const renderSyncCell = (props) => {
    return (
      <Grid container justifyContent="center">
        <IconButton
          variant="solid"
          disabled={checkInsDrill.loading}
          onClick={() => {
            if (props.row) {
              dispatch(listCheckInsRigs(props.row.Customer));
            }
            setTimeout(() => {
              setIsRigPhotosOpen(true);
            }, 1000);
          }}
        >
          <CameraAlt />
        </IconButton>
      </Grid>
    );
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
      <Map
        showRigsToggleButton={true}
        data={checkInsDrill}
        mapType={mapType}
        mapData={checkInsDrill.data}
        markerData={checkInsDrill.data}
        latitudeField={'lat'}
        longitudeField={'long'}
        mapPinField={'lastUpdate'}
        popUpLabel={['Customer', 'UserEmail', 'comment']}
        excludePopupLabel={true}
        mapData2={rigs.marker_data}
        markerData2={rigs.marker_data}
        latitudeField2={'RigLatitudeWGS84'}
        longitudeField2={'RigLongitudeWGS84'}
        secondaryPopup={'RigName_Number'}
        getGoogleMapPin={getGoogleMapPin}
        getMapPin={getMapPin}
        getMapPin2={getMapPin2}
        gmapRowId={gmapRowId}
        rowId={rowId}
        referenceKey={'grid_id'}
        setIconPinSize={setIconPinSize}
        onMarkerClick={handleMarkerClick}
        showRigPinsByDefault={true}
      />
      </Grid>
      <Grid item xs={12}>
        <Datagrid
          apiRef={apiRef}
          data={checkInsDrill.data}
          columns={masterColumn(renderSyncCell)}
          onCol
          loading={checkInsDrill.loading}
          getRowId={(row) => row.grid_id}
          autoPageSize
          autoHeight={false}
          pagination={false}
          hideFooter
          onRowClick={({ row: well }) => {
            if (mapType === MAP_TYPES.GOOGLEMAP) {
              setGmapRowId(well._id);
            } else {
              setRowId(well);
            }
          }}
          experimentalFeatures={{ newEditingApi: true }}
          toolbar={{
            options: {
              columns: true,
              filters: true,
              export: false,
            },
          }}
          filterMode="server"
          onFilterModelChange={(model) => {
            setFilter(model.items);
            const items = model.items.map(filter => {
              if (filter.columnField === 'lastUpdate') {
                return filter;
              } else if (filter.columnField === 'lat' || filter.columnField === 'long') {
                return { ...filter, columnField: filter.columnField, value: Number(filter.value) };
              } else {
                return { ...filter, columnField: filter.columnField };
              }
            });
            const filterModels = { ...model, items };
            dispatch({
              type: REDUX_ACTIONS.CHECKINS_DRILL_SET_FILTER,
              payload: filterModels.items.length ? filterModels : {}
            });
          }}
          sortingMode="server"
          onSortModelChange={(sortModel) => {
            const sort = sortModel.reduce((result, item) => {
              result[item.field] = item.sort;
              return result;
            }, {});
            dispatch({
              type: REDUX_ACTIONS.CHECKINS_DRILL_SET_SORT,
              payload: sort
            });
            dispatch({
              type: REDUX_ACTIONS.CHECKINS_DRILL_SET_CURRENT_PAGE,
              payload: 1
            });
            dispatch(listCheckInsDrill());
          }}
          getRowClassName={(params) => (params.id === rowId?.grid_id ? classes.highlightRow : '')}
          />
           <CustomPagination
          currentPage={checkInsDrill.currentPage - 1}
          limit={checkInsDrill.limit}
          totalCount={checkInsDrill.totalCount}
          handlePagination={handlePagination}
          loading={checkInsDrill.loading}
        />
      </Grid>
      {isRigPhotosOpen
        ? <RigsPhotosModal
          open={isRigPhotosOpen}
          data={checkInsDrill.checkInsRigs.data}
          handleClose={() => {
            setIsRigPhotosOpen(false);
          }}
        />
        : null}
    </Grid>
  );
};

CheckInsDrill.propTypes = {
  row: PropTypes.object,
};

export default CheckInsDrill;
