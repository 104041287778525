/* eslint-disable multiline-ternary */

import React, { useState } from 'react';
import { Grid, IconButton, Typography, Box } from '@mui/material';
import { CancelPresentationRounded, NavigateNext, NavigateBefore } from '@mui/icons-material';
import PropTypes from 'prop-types';
import styles from './styles';
import ModalDialog from '../../ui/ModalDialog';
import Carousel from 'react-material-ui-carousel';
import RigPhotoCard from './rigPhotoCard/';

const RigsPhotosModal = ({
  handleClose,
  data,
  open,
}) => {
  const classes = styles();
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalPages = Math.ceil(data.length / 3);

  const getModalTitle = () => {
    return (
      <>
      <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
           {data[0]?.sRig}
          </Grid>
          <Grid item>
          <IconButton
              onClick={handleClose}
            >
              <CancelPresentationRounded fontSize='large'/>
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <ModalDialog
      title={getModalTitle()}
      isOpen={open}
      onClose={handleClose}
      maxWidth="lg"
      dialogClass={classes.dialogModal}
    >
   <Grid container spacing={2} marginTop="20px" minHeight="450px" justifyContent="center">
   <Grid item xs={12} style={{ textAlign: 'center' }}>
    {data.length > 0 ? (
      <Carousel
        swipe={false}
        autoPlay={false}
        NextIcon={<NavigateNext />}
        PrevIcon={<NavigateBefore />}
        className={classes.carousel}
        index={currentIndex}
        onChange={(index) => setCurrentIndex(index)}
        navButtonsWrapperProps={{
          style: {
            display: 'none',
          }
        }}
        indicatorContainerProps={{
          style: {
            zIndex: 1,
            marginTop: '20px',
          }
        }}
      >
        {Array.from({ length: totalPages }).map((_, pageIndex) => (
          <Box
            key={pageIndex}
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            padding="0 10px"
          >
            {/* Previous Icon */}
            <IconButton
              disableRipple
              onClick={() => setCurrentIndex(Math.max(0, currentIndex - 1))}
              disabled={currentIndex === 0}
              className={classes.actionIcon}
            >
              <NavigateBefore sx={{ fontSize: '50px' }} />
            </IconButton>

            {/* Cards */}
            <Grid container spacing={2} justifyContent="center" sx={{ flexGrow: 1 }}>
              {data.slice(pageIndex * 3, pageIndex * 3 + 3).map((item, index) => (
                <Grid item xs={4} key={index}>
                  <RigPhotoCard
                    avatarLetter={item.sRig.charAt(0)}
                    comment={item.comment}
                    title={`${item.sRig}`}
                    image={item.picture}
                    email={item?.userEmail}
                  />
                </Grid>
              ))}
            </Grid>

            {/* Next Icon */}
            <IconButton
              disableRipple
              onClick={() => setCurrentIndex(Math.min(totalPages - 1, currentIndex + 1))}
              disabled={currentIndex === totalPages - 1}
              className={classes.actionIcon}
            >
              <NavigateNext sx={{ fontSize: '50px' }} />
            </IconButton>
          </Box>
        ))}
      </Carousel>
    ) : (
      <Typography variant="h6" sx={{ textAlign: 'center' }}>
        No Images Found
      </Typography>
    )}
  </Grid>
</Grid>
    </ModalDialog>
  );
};
RigsPhotosModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  data: PropTypes.any
};
export default RigsPhotosModal;
