/* react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import Datagrid from '../ui/Datagrid';
import { columns, renderCell, renderTimestampCell } from './table_config';
import {
  getAssetData,
  getChartPreferences,
  getEventData,
  getEvents,
  updateChartPreferences,
} from '../../store/actions/assetData';
import { listJobs } from '../../store/actions/jobs';
import CustomPopoover from '../ui/CustomPopover';
import { FileUpload } from '@mui/icons-material';
import styles from './styles';
import clsx from 'clsx';
import { Backdrop, CircularProgress, Grid } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import {
  ACTUATOR_FILTERS,
  CHART_TIMESTAMP_FORMAT,
  DRILLING_FILTERS,
  GRID_COLUMNS_TO_EXCLUDE,
  DEVICE_CLASSIFICATION,
} from '../../utils/constants';
import { dateFormatter } from '../../utils/datetimeParser';
import { REDUX_ACTIONS, ASSET_DATA_TYPES } from '../../shared/config/constants';
import CanvasJSChart from '../ui/canvasjs';
import DataGridColorPicker from '../ui/DataGridColorPicker';
import {
  convertArray,
  getPropertyArray,
  updateObjectFields,
  updateProperties,
  updateArrayObjectKeys,
  haveSameKeys,
} from '../../utils/objectHelpers';
import PropTypes from 'prop-types';
import GaugeChart from 'react-gauge-chart';
import { enqueueSnackbar } from '../../store/actions/snackbar';
import store from '../../store/store';
import { generateDefaultActuatorColumns } from '../mobile/utils/generateDefaultActuatorData';
import { generateDefaultDrillingColumns } from '../mobile/utils/generateDefaultDrillingData';

const AssetData = () => {
  const selectedColumHeaderRefs = useRef([]);
  const { assetData } = useSelector((state) => state);
  const { preferences, userId } = useSelector((state) => state.currentUser);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // use state and not common variable to catch url change and trigger refresh in useEffect
  const [assetDataType] = useState(
    searchParams.get('assetDataType') ?? ASSET_DATA_TYPES.ACTUATORDATA
  );
  const [filterId] = useState(searchParams.get('filterId') ?? '2');
  const classes = styles();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const apiRef = useGridApiRef();
  const [initialState, setInitialState] = useState({});
  const [isColumnResized, setIsColumnResized] = useState(false);
  const latestRecordRef = useRef([]);
  const [events, setEvents] = useState([]);
  const jobId = assetDataType.split('_')[1];
  const jobs = useSelector((state) => state.jobs);
  const [selectedJob, setSelectedJob] = useState(false);
  const modalOpenRef = useRef(false);
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const mounted = useRef(null);
  const chartsMounted = useRef(null);
  const [loadingPreferences, setLoadingPreferences] = useState(true);
  const refreshTime = +process.env.REACT_APP_REFRESH_TIME;
  const [dataColumns, setDataColumns] = useState([]);

  useEffect(() => {
    if (assetDataType.includes(ASSET_DATA_TYPES.DRILLINGDATA) && filterId === '2') return;

    const timer = setTimeout(() => {
      const body = {
        input: {
          userId,
          jobId,
          platform: DEVICE_CLASSIFICATION.DESKTOP,
          filterId: Number(filterId)
        }
      };
      if (!chartsMounted.current && loadingPreferences) {
        dispatch(getChartPreferences(body, () => setLoadingPreferences(false)));
      } else if (chartsMounted.current && !loadingPreferences) {
        const updatedColumnsDefinition = updateArrayObjectKeys(preferences.datachartSettings.columnVisibilityModel, preferences.datachartSettings.columnsDefinition);
        if (updatedColumnsDefinition?.length > 1 && Object.keys(preferences.datachartSettings?.columnVisibilityModel).length > 1) {
          body.input.preferences = {
            datachartSettings: {
              ...preferences.datachartSettings,
              columnsDefinition: updatedColumnsDefinition,
              aspectRatioPage: preferences.aspectRatioPage,
              viewportMaximum: null,
              viewportMinimum: null
            }
          };
        }

        dispatch(updateChartPreferences(body));
      }
    }, 100);
    return () => clearTimeout(timer);
  }, [
    preferences.datachartSettings.datakeys,
    preferences.datachartSettings.columnsColorDefinition,
    preferences.datachartSettings.columnsRange,
    preferences.aspectRatioPage
  ]);

  useEffect(() => {
    if (data?.length && !chartsMounted.current) {
      setTimeout(() => {
        chartsMounted.current = true;
      }, 1000);
    }
  }, [data]);

  useEffect(() => {
    if (jobs?.data?.length) {
      const selectedJob = jobs.data.find((element) => {
        return element._id === jobs.currentJob._id;
      });
      setSelectedJob(selectedJob);
    }
  }, [jobs.data]);

  useEffect(() => {
    // Set ref as modal state changes
    assetData.isDateRangeModalOpen
      ? modalOpenRef.current = true
      : modalOpenRef.current = false;
  }, [assetData.isDateRangeModalOpen]);

  const setNeedleColor = (state) => {
    const prioritySet = { red: 1, '#FFBF00': 2, green: 3 };

    const getColor = (status) => {
      if (status === 1 || status === 3) {
        return '#FFBF00'; // amber
      }

      if (status === 2 || status === 4) {
        return 'red';
      }

      return 'green';
    };

    const colors = selectedJob?.alarms?.alarms?.map((alarm) => {
      return (
        alarm.message.includes(state) && getColor(alarm.state)
      );
    }).filter(item => item);

    if (colors?.length > 1) {
      // if multiple alarms for same signal, sort based on higest priority, ie; red > amber > green
      colors.sort(function(a, b) {
        return prioritySet[a] - prioritySet[b];
      });
    }

    return colors ? colors[0] : '#777';
  };

  const setColumnVisibilityModel = (newModel) => {
    const selectedCol = Object.fromEntries(
      Object
        .entries(newModel)
        .filter(([, val]) => val === true)
    );

    delete selectedCol?.timestamp;

    if (Object.keys(selectedCol).length > 10) {
      dispatch(
        enqueueSnackbar(
          'Cannot add more than 10 columns',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return;
    }

    dispatch({
      type: REDUX_ACTIONS.SET_DATA_CHART_PREFERENCES,
      payload: { columnVisibilityModel: { ...preferences.datachartSettings.columnVisibilityModel, ...newModel } },
    });
  };

  function _data() {
    const arr = [];
    for (let i = 0; i < assetData.data.length; ++i) {
      const item = {
        timestamp: new Date(assetData.data[i].timestamp).getTime(),
      };

      preferences.datachartSettings.datakeys?.forEach(
        (datakey) => (item[datakey] = parseFloat(assetData.data[i][datakey]))
      );

      arr.push(item);
    }
    setData(arr);
    setEvents(assetData.currentEvents);
  }

  const onColumnVisibilityChange = (params, _evt, _details) => {
    if (Object.keys(params).length === 0) {
      const newDataKeysValues = Object.keys(
        preferences.datachartSettings.columnsInitialState ?? {}
      ).filter((item) => item !== 'timestamp');
      dispatch({
        type: REDUX_ACTIONS.SET_DATA_CHART_PREFERENCES,
        payload: { datakeys: newDataKeysValues },
      });
      return;
    }

    let datakeys = preferences.datachartSettings.datakeys ?? [];

    for (const param in params) {
      if (param === 'timestamp') {
        continue;
      }

      if (params[param]) {
        if (!datakeys.includes(param)) {
          datakeys.push(param);
        }
      } else if (!params[param] && datakeys.length > 0) {
        datakeys = datakeys.filter((item) => item !== param);
      }
    }

    datakeys.sort();

    dispatch({
      type: REDUX_ACTIONS.SET_DATA_CHART_PREFERENCES,
      payload: { datakeys },
    });
  };

  const dateRangeRef = useRef(null);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const newAssetDataType =
      searchParams.get('assetDataType') ?? ASSET_DATA_TYPES.ACTUATORDATA;
    if (newAssetDataType !== assetDataType) {
      // force refresh to clean routing and load new data.
      window.location.reload();
    }
  }, [location.search]);

  useEffect(() => {
    if (dateRangeRef.current) {
      dateRangeRef.current.isDateChanged = true;
    }
    dispatch({
      type: REDUX_ACTIONS.SET_DATA_CHART_PREFERENCES,
      payload: {
        viewportMinimum: null,
        viewportMaximum: null,
      },
    });
  }, [assetData.dateRange.start, assetData.dateRange.end]);

  useEffect(() => {
    if (
      !assetData.loading &&
      (dateRangeRef.current?.isDateChanged || !assetData.dateRange.paused)
    ) {
      if (assetData.dateRange.start && assetData.dateRange.end) {
        startDateRef.current = assetData.dateRange.start;
        endDateRef.current = assetData.dateRange.end;
        dispatch(
          getAssetData(
            assetDataType,
            {
              preset: assetData.dateRange.preset,
              start: assetData.dateRange.start,
              end: assetData.dateRange.end
            },
            false,
            !assetData.dateRange.paused
          )
        );
      } else {
        dispatch(
          getAssetData(
            assetDataType,
            { preset: assetData.dateRange.preset },
          )
        );
      }
    }

    dateRangeRef.current = assetData.dateRange;
  }, [assetData.dateRange]);

  useEffect(() => {
    if (searchParams.size !== 0 && !events.loading && events.length === 0) {
      dispatch(getEvents(assetDataType.slice(13), filterId));
    }
    dispatch({
      type: REDUX_ACTIONS.SET_CURRENT_JOB,
      payload: {
        jobId,
      },
    });
    dispatch(listJobs());
  }, []);

  useEffect(() => {
    const pageTitle = searchParams.get('pageTitle');
    if (pageTitle) {
      dispatch({
        type: REDUX_ACTIONS.MENU_SET_TITLE,
        payload: pageTitle,
      });
    }
    const interval = setInterval(() => {
      if (!dateRangeRef.current.paused) {
        if (latestRecordRef.current.length > 0 && modalOpenRef.current === false) {
          dispatch(
            getAssetData(
              assetDataType,
              {
                preset: dateRangeRef.current.preset,
                end: latestRecordRef.current[0].timestamp,
              },
              false,
              true,
              generateGridHeaders
            )
          );
        }
      }
    }, 15000);

    return () => {
      clearInterval(interval);
      dispatch({
        type: REDUX_ACTIONS.MENU_SET_TITLE,
        payload: '',
      });
    };
  }, []);

  useEffect(() => {
    if (!refreshTime) return;
    const interval = setInterval(() => {
      if (latestRecordRef.current.length > 0) {
        window.location.reload();
      }
    }, refreshTime);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (preferences.datachartSettings?.datakeys?.length === 0) {
        setData([]);
      }
      if (preferences.datachartSettings?.datakeys?.length && Object.keys(preferences.datachartSettings.columnVisibilityModel).length > 0) {
        const latest = assetData.data.slice(assetData.data.length - 1);
        latestRecordRef.current = latest;
        _data();
      }
    }, 100);
    return () => clearTimeout(timer);
  }, [preferences.datachartSettings, assetData.data]);

  const generateGridHeaders = (columnsInitialState, columnsColorDefinition, datakeys) => {
    const columnsDefinition = [];

    Object.keys(columnsInitialState).forEach(key => {
      if (key === 'timestamp') return;
      const colDef = {
        field: key,
        headerName: key,
        headerAlign: 'center',
        editable: false,
        groupable: false,
        aggregable: false,
        sortable: false,
        dataGeneratorUniquenessEnable: true,
        pinnable: false,
        width: key.length > 20 ? key.length * 10 : 190,
        hide: !columnsInitialState[key],
      };

      colDef.renderHeader = (props) => {
        const { currentUser } = store.getState();
        const handleColumnRange = (fieldValue, key) => {
          const val = {};
          val[key] = fieldValue;

          const newRange = {
            [props.field]: val,
          };

          const objRange = currentUser.preferences.datachartSettings
            .columnsRange
            ? { ...currentUser.preferences.datachartSettings.columnsRange }
            : newRange;
          if (currentUser.preferences.datachartSettings.columnsRange) {
            if (objRange[props.field]) {
              objRange[props.field][key] = fieldValue;
            } else {
              objRange[props.field] = val;
            }
          }

          dispatch({
            type: REDUX_ACTIONS.SET_DATA_CHART_PREFERENCES,
            payload: {
              columnsRange: objRange,
            },
          });
        };

        const handleResetRange = (key) => {
          handleColumnRange(null, key);
        };
        return (
              <>
                <CustomPopoover
                  tootipTitle="min"
                  onSubmit={handleColumnRange}
                  onReset={handleResetRange}
                  icon={<FileUpload />}
                />
                <CustomPopoover
                  tootipTitle="max"
                  onSubmit={handleColumnRange}
                  onReset={handleResetRange}
                  icon={<FileUpload style={{ transform: 'rotate(180deg)' }} />}
                />
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <DataGridColorPicker
                    key={`colorPicker_${props.field}`}
                    field={props.field}
                  />
                </div>
                <span style={{
                  color: selectedColumHeaderRefs.current.includes(props.field) ? 'gray' : 'black'
                }}>{props.field}</span>
              </>
        );
      };
      colDef.renderCell = (params) => {
        return (
              <strong className={classes.renderCell}>{params.value}</strong>
        );
      };

      columnsDefinition.push(colDef);
    });

    columnsDefinition.unshift({
      field: 'timestamp',
      headerName: '',
      headerAlign: 'center',
      editable: false,
      groupable: false,
      aggregable: false,
      sortable: false,
      dataGeneratorUniquenessEnable: true,
      pinnable: false,
      width: 200,
      hide: false,
      hideable: false,
      headerClassName: 'timestampHeader',
      renderCell: () => <strong>{''}</strong>,
    });

    dispatch({
      type: REDUX_ACTIONS.SET_DATA_CHART_PREFERENCES,
      payload: {
        columnsDefinition,
        columnsInitialState,
        columnVisibilityModel: columnsInitialState,
        columnsColorDefinition,
        datakeys
      },
    });
    setDataColumns(columnsDefinition);
    setInitialState({ ...columnsInitialState });
    mounted.current = true;
  };

  useEffect(() => {
    if (assetDataType.includes(ASSET_DATA_TYPES.ACTUATORDATA) || (assetDataType.includes(ASSET_DATA_TYPES.DRILLINGDATA) && filterId === '0')) { // update actuator defaults
      if ((assetData.data.length > 0) && !mounted.current) {
        if (
          Object.keys(preferences.datachartSettings.columnVisibilityModel)
            .length > 0
        ) {
          const latestData = _.omit(assetData.data.at(-1), ['grid_id', '_id', 'DATE', 'TIME', 'actuator']);
          if (Object.keys(latestData).length && !haveSameKeys(latestData, preferences.datachartSettings.columnVisibilityModel)) {
            const currentPreferences = JSON.parse(JSON.stringify(preferences.datachartSettings));
            for (const key in latestData) {
              // eslint-disable-next-line no-prototype-builtins
              if (latestData.hasOwnProperty(key)) {
                if (!(key in currentPreferences.columnVisibilityModel)) {
                  currentPreferences.columnVisibilityModel[key] = false;
                  currentPreferences.columnsColorDefinition[key] = '000';
                }
              }
            }
            generateGridHeaders(currentPreferences.columnVisibilityModel, currentPreferences?.columnsColorDefinition, currentPreferences?.datakeys);
            return;
          }
          if (preferences.datachartSettings?.datakeys.length) {
            generateGridHeaders(preferences.datachartSettings?.columnVisibilityModel, preferences.datachartSettings?.columnsColorDefinition, preferences.datachartSettings?.datakeys);
          }
          return;
        }
        const { columnsInitialState, columnsColorDefinition, datakeys } = assetDataType.split('_')[0].toLocaleLowerCase() === 'drillingdata' ? generateDefaultDrillingColumns(assetData.data[0]) : generateDefaultActuatorColumns(assetData.data[0]);
        generateGridHeaders(columnsInitialState, columnsColorDefinition, datakeys);
      }
    }
  }, [assetData.data, selectedColumHeaderRefs]);

  useEffect(() => {
    if (assetDataType.includes(ASSET_DATA_TYPES.ACTUATORDATA) || (assetDataType.includes(ASSET_DATA_TYPES.DRILLINGDATA) && filterId === '0')) {
      return; // updated default columns for actuator data - reformat once drilling defaults updated
    }

    if ((assetData.data.length > 0) && !mounted.current) {
      if (
        Object.keys(preferences.datachartSettings.columnVisibilityModel)
          .length > 0
      ) {
        if (preferences.datachartSettings?.datakeys.length) {
          generateGridHeaders(preferences.datachartSettings?.columnVisibilityModel, preferences.datachartSettings?.columnsColorDefinition, preferences.datachartSettings?.datakeys);
        }
        return;
      }
      let columnsDefinition = [];
      let columnsInitialState = {};
      let isFirstColumnAssigned = false;

      Object.keys(assetData.data[0]).forEach((key) => {
        if (GRID_COLUMNS_TO_EXCLUDE.includes(key)) {
          return;
        }
        const isTimestampColumn = key === 'timestamp';
        const colDef = {
          field: key,
          headerName: key,
          headerAlign: 'center',
          editable: false,
          groupable: false,
          aggregable: false,
          sortable: false,
          dataGeneratorUniquenessEnable: true,
          pinnable: false,
          width: key.length > 20 ? key.length * 10 : 190,
        };
        const colInitState = {};

        if (isTimestampColumn) {
          colDef.width = 200;
          colDef.hide = false;
          colDef.hideable = false;
          colInitState[key] = true;
          colDef.headerClassName = 'timestampHeader';
          colDef.renderCell = () => (
            <strong className={classes.renderCell}>{''}</strong>
          );
        } else if (filterId === '2' && !isFirstColumnAssigned) {
          dispatch({
            type: REDUX_ACTIONS.SET_DATA_CHART_PREFERENCES,
            payload: { datakeys: [key] },
          });
          isFirstColumnAssigned = true;
          colInitState[key] = true;
          colDef.hide = false;
        } else {
          colInitState[key] = false;
          colDef.hide = true;
        }

        columnsInitialState = { ...columnsInitialState, ...colInitState };

        if (isTimestampColumn) {
          colDef.valueGetter = ({ value }) => {
            return (
              value &&
              dateFormatter(value, {
                ...CHART_TIMESTAMP_FORMAT,
                second: '2-digit',
              })
            );
          };
        } else {
          colDef.renderHeader = (props) => {
            const { currentUser } = store.getState();
            const handleColumnRange = (fieldValue, key) => {
              const val = {};
              val[key] = fieldValue;

              const newRange = {
                [props.field]: val,
              };

              const objRange = currentUser.preferences.datachartSettings
                .columnsRange
                ? { ...currentUser.preferences.datachartSettings.columnsRange }
                : newRange;
              if (currentUser.preferences.datachartSettings.columnsRange) {
                if (objRange[props.field]) {
                  objRange[props.field][key] = fieldValue;
                } else {
                  objRange[props.field] = val;
                }
              }

              dispatch({
                type: REDUX_ACTIONS.SET_DATA_CHART_PREFERENCES,
                payload: {
                  columnsRange: objRange,
                },
              });
            };

            const handleResetRange = (key) => {
              handleColumnRange(null, key);
            };
            return (
              <>
                <CustomPopoover
                  tootipTitle="min"
                  onSubmit={handleColumnRange}
                  onReset={handleResetRange}
                  icon={<FileUpload />}
                />
                <CustomPopoover
                  tootipTitle="max"
                  onSubmit={handleColumnRange}
                  onReset={handleResetRange}
                  icon={<FileUpload style={{ transform: 'rotate(180deg)' }} />}
                />
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <DataGridColorPicker
                    key={`colorPicker_${props.field}`}
                    field={props.field}
                  />
                </div>
                <span style={{
                  color: selectedColumHeaderRefs.current.includes(props.field) ? 'gray' : 'black'
                }}>{props.field}</span>
              </>
            );
          };
          colDef.renderCell = (params) => {
            return (
              <strong className={classes.renderCell}>{params.value}</strong>
            );
          };
        }

        columnsDefinition.push(colDef);
      });
      // Handles selected filter settings from jobs page
      if (
        assetDataType.includes(ASSET_DATA_TYPES.DRILLINGDATA) &&
        filterId !== 'none' &&
        DRILLING_FILTERS[filterId].fields.length > 0
      ) {
        const filterDefaultColumnNames = getPropertyArray(
          DRILLING_FILTERS[filterId].fields,
          'column'
        );
        const filterDefaultColumnColors = getPropertyArray(
          DRILLING_FILTERS[filterId].fields,
          'color'
        );

        const drillinFilterInitialState = updateProperties(
          columnsInitialState,
          filterDefaultColumnNames,
          true
        );
        // drillinFilterInitialState = updateProperties(drillinFilterInitialState, ['BLKPOS'], false);
        columnsInitialState = drillinFilterInitialState;

        const drillingColumnDefinitions = updateObjectFields(
          columnsDefinition,
          'field',
          filterDefaultColumnNames,
          'hide',
          false
        );
        // drillingColumnDefinitions = updateObjectFields(drillingColumnDefinitions, 'field', ['BLKPOS'], 'hide', true);
        columnsDefinition = drillingColumnDefinitions;

        const drillingFilterColors = convertArray(
          filterDefaultColumnNames,
          filterDefaultColumnColors
        );
        dispatch({
          type: REDUX_ACTIONS.SET_DATA_CHART_PREFERENCES,
          payload: {
            columnsDefinition,
            columnsInitialState: { ...columnsInitialState },
            columnsColorDefinition: { ...drillingFilterColors },
          },
        });
      } else {
        const filterDefaultColumnNames = getPropertyArray(
          ACTUATOR_FILTERS[0].fields,
          'column'
        );

        const filterDefaultColumnColors = getPropertyArray(
          ACTUATOR_FILTERS[0].fields,
          'color'
        );

        const actuatorFilterInitialState = updateProperties(
          columnsInitialState,
          filterDefaultColumnNames,
          true
        );

        columnsInitialState = actuatorFilterInitialState;

        const actuatorColumnDefinitions = updateObjectFields(
          columnsDefinition,
          'field',
          filterDefaultColumnNames,
          'hide',
          false
        );

        columnsDefinition = actuatorColumnDefinitions;

        const actuatorFilterColors = convertArray(
          filterDefaultColumnNames,
          filterDefaultColumnColors
        );
        dispatch({
          type: REDUX_ACTIONS.SET_DATA_CHART_PREFERENCES,
          payload: {
            columnsDefinition,
            columnsInitialState: { ...columnsInitialState },
            columnsColorDefinition: { ...actuatorFilterColors },
            datakeys: Object.keys(columnsInitialState).filter(key => key !== 'timestamp' && columnsInitialState[key])
          },
        });
      }
      setDataColumns(columnsDefinition);
      setInitialState({ ...columnsInitialState });
      mounted.current = true;
    }
  }, [assetData.data, selectedColumHeaderRefs]);

  useEffect(() => {
    return () => {
      dispatch({
        type: REDUX_ACTIONS.ASSET_DATA_RESET,
      });
      dispatch({
        type: REDUX_ACTIONS.DATA_CHART_PREFERENCES_RESET,
      });
    };
  }, []);

  useEffect(() => {
    if (
      Object.keys(preferences.datachartSettings.columnVisibilityModel)
        .length > 0
    ) {
      dispatch({
        type: REDUX_ACTIONS.SET_DATA_CHART_PREFERENCES,
        payload: {
          columnVisibilityModel:
          preferences.datachartSettings.columnVisibilityModel,
          datakeys: Object.keys(preferences.datachartSettings.columnVisibilityModel).filter(key => key !== 'timestamp' && preferences.datachartSettings.columnVisibilityModel[key])
        },
      });
    } else {
      dispatch({
        type: REDUX_ACTIONS.SET_DATA_CHART_PREFERENCES,
        payload: {
          columnVisibilityModel: initialState,
        },
      });
    }
  }, [initialState]);

  useEffect(() => {
    if (assetData.data.length && mounted.current) {
      const latest = assetData.data.slice(assetData.data.length - 1);
      setDataColumns((prev) => prev?.map((item) => {
        if (item.field === 'timestamp') {
          return {
            ...item,
            headerName: latest?.[0]?.timestamp
              ? dateFormatter(latest?.[0]?.timestamp, {
                ...CHART_TIMESTAMP_FORMAT,
                second: '2-digit',
              })
              : 'timestamp',
          };
        }
        return item;
      }));
    }
  }, [assetData.data]);

  const chartBrushEndIndex = {};
  if (preferences.datachartSettings.brushParameters?.endIndex) {
    chartBrushEndIndex.endIndex =
      preferences.datachartSettings.brushParameters?.endIndex;
  }

  const rangeChanging = (e) => {
    dispatch({
      type: REDUX_ACTIONS.SET_DATA_CHART_PREFERENCES,
      payload: {
        viewportMinimum: preferences.chartViewMode === 0 ? e.axisY[0].viewportMinimum : e.axisX[0].viewportMinimum,
        viewportMaximum: preferences.chartViewMode === 0 ? e.axisY[0].viewportMaximum : e.axisX[0].viewportMaximum,
      },
    });
  };

  const handleColumnHeaderClick = (params) => {
    const isAlreadySelected = selectedColumHeaderRefs.current.includes(params.field);
    if (isAlreadySelected) {
      const updatedColumnheaders = selectedColumHeaderRefs.current.filter(
        (item) => item !== params.field
      );
      selectedColumHeaderRefs.current = updatedColumnheaders;
      dispatch({
        type: REDUX_ACTIONS.SET_DATA_CHART_PREFERENCES,
        payload: {
          labelsToHide: updatedColumnheaders
        },
      });
      return;
    }
    const updatedColumnheaders = [...selectedColumHeaderRefs.current, params.field];
    selectedColumHeaderRefs.current = updatedColumnheaders;
    dispatch({
      type: REDUX_ACTIONS.SET_DATA_CHART_PREFERENCES,
      payload: {
        labelsToHide: updatedColumnheaders
      },
    });
  };

  const handleRowClick = (params) => {
    dispatch({
      type: REDUX_ACTIONS.ASSET_DATA_PAUSE,
    });
    dispatch(
      getEventData(
        assetDataType,
        {
          start: params.startTime,
          end: params.endTime,
        },
        true,
        false
      )
    );
  };

  const setPresetDate = (_e) => {
    // keeping this method in case we need this functionality
    // eslint-disable-next-line no-console
    // console.log(
    //   e.dataSeries.type + ' x:' + e.dataPoint.x + ', y: ' + e.dataPoint.y
    // );
    // to-do:  POST witsData/data to get X data (where X is preset amount)
    return null;
  };

  return (
    <>
      <Grid container justifyContent="center">
        {
          /* prettier-ignore */
          preferences.chartViewMode === 3
            ? (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: 600,
              alignItems: 'center',
              flexWrap: 'wrap',
              overflowY: 'scroll'
            }}
          >
            {preferences.datachartSettings?.datakeys?.map((columnDef) => {
              const columnValue = latestRecordRef.current[0][columnDef];
              const colorStart =
                preferences.datachartSettings.columnsColorDefinition[
                  columnDef
                ];
              const colorGaugeChart = colorStart
                ? ['#fff', `#${colorStart}`]
                : ['#000000'];

              let min = Math.min.apply(
                Math,
                data.map(function (o) {
                  return o[columnDef];
                })
              );

              let max = Math.max.apply(
                Math,
                data.map(function (o) {
                  return o[columnDef];
                })
              );

              const columnsRange =
                preferences.datachartSettings.columnsRange;

              let sumMinMax = Math.abs(min) + max;

              let floatColumnValue = Math.abs(min) + parseFloat(columnValue);
              const intColumnValue = Math.floor(columnValue);

              let percentValue = intColumnValue === 0 ? 0 : (floatColumnValue / sumMinMax);

              if (columnsRange) {
                if (columnsRange[columnDef]?.min) {
                  min = columnsRange[columnDef].min;
                }

                if (columnsRange[columnDef]?.max) {
                  max = columnsRange[columnDef].max;
                }

                sumMinMax = Math.abs(parseFloat(min)) + parseFloat(max);
                floatColumnValue = Math.abs(min) + parseFloat(columnValue);
                percentValue = intColumnValue === 0 ? 0 : (floatColumnValue / sumMinMax);
              }

              return (
                <Grid
                  key={`key-${columnDef}`}
                  item
                  xs={4}
                  className={clsx(classes.speedChartContainer)}
                  textAlign="center"
                >
                  <div className={classes.speedLabel}>{columnDef}</div>
                  <GaugeChart
                    id={`gauge-chart-${columnDef}`}
                    animate={false}
                    nrOfLevels={20}
                    percent={percentValue}
                    formatTextValue={() => parseFloat(columnValue).toFixed(2)}
                    colors={colorGaugeChart}
                    needleColor={setNeedleColor(columnDef)}
                    textColor="#000"
                    animDelay={0}
                    style={{ width: '100%', height: 250 }}
                  />
                </Grid>
              );
            })}
          </div>
              )
            : (
              <>
                { data.length > 0 && (
                  <Grid
                    item
                    xs={ 12 }
                    className={ clsx(classes.speedChartContainer) }
                  >
                    <CanvasJSChart
                      data={ data }
                      yAxisLabelsToHide={ preferences.datachartSettings.labelsToHide }
                      rangeChanging={ rangeChanging }
                      viewportMinimum={
                        preferences.datachartSettings.viewportMinimum
                      }
                      viewportMaximum={
                        preferences.datachartSettings.viewportMaximum
                      }
                      setPresetDate={ setPresetDate }
                      height={ 600 }
                      stackedView={ preferences.chartViewMode === 1 ? 'horizontal' : preferences.chartViewMode === 0 ? 'vertical' : null }
                      columnsVisibilityChange={ preferences.datachartSettings.datakeys }
                    />
                  </Grid>
                ) }
              </>
              )
        }
        <Grid
          item
          xs={12}
          sx={{
            '& .timestampHeader': {
              fontWeight: 'bold',
              fontSize: 'medium',
            },
          }}
        >
          <Datagrid
            apiRef={apiRef}
            data={latestRecordRef.current}
            columns={dataColumns}
            loading={assetData.loading}
            onColumnHeaderClick={handleColumnHeaderClick}
            columnVisibilityModel={
              preferences.datachartSettings.columnVisibilityModel ?? {}
            }
            onColumnVisibilityModelChange={(newModel) => {
              //  Check if event is triggered by column resizing
              if (!isColumnResized) {
                onColumnVisibilityChange(newModel);
                setColumnVisibilityModel(newModel);
              } else {
                setIsColumnResized(false);
              }
            }}
            onColumnResize={(_params) => {
              setIsColumnResized(true);
            }}
            setColumnVisibilityModel={setColumnVisibilityModel}
            initialState={{ pinnedColumns: { left: ['timestamp'] } }}
            height="auto"
            hideFooter={true}
            toolbar={{
              options: {
                columns: true,
                filters: false,
                density: false,
                export: {
                  enabled: true,
                  options: ['csv'],
                  assetData: assetData?.data,
                  selectedColumns: preferences?.datachartSettings,
                },
              },
            }}
            componentsProps={{
              columnsPanel: {
                sx: {
                  '& .MuiDataGrid-panelFooter button': {
                    display: 'none',
                  },
                },
              },
            }}
          />
        </Grid>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={false}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>

      <Grid
        container
        justifyContent="center"
      >
        <Grid item xs={12}>
          <Datagrid
            sx={{ '.MuiDataGrid-columnHeaderTitle': { fontWeight: 'normal' } }}
            pageSizeOptions={[5, 10, 25]}
            pageSize={10}
            data={events}
            columns={columns(renderCell, renderTimestampCell)}
            loading={assetData.eventsLoading}
            height="auto"
            onCellClick={(params) => {
              handleRowClick(params.row);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

AssetData.propTypes = {
  field: PropTypes.object,
};

export default AssetData;
