import Tooltip from '@mui/material/Tooltip';

const columns = (
  showColumn,
  renderCell,
  renderDataCell,
  renderReportsCell,
  renderAssetsCell,
  renderStatusCell,
  renderAlarmsCell,
  renderTruFlowCell,
  renderJobsRigEditCell,
  renderJobsWellEditCell,
  dropdownEditable,
  renderHydraulicCell,
  renderFavoriteEditCell,
  renderActions,
) => [
  {
    field: 'Operator',
    headerName: 'Operator',
    width: 200,
    hide: true,
    renderCell,
  },
  {
    field: 'Latitude',
    headerName: 'Latitude',
    width: 100,
    hide: true,
    renderCell,
  },
  {
    field: 'Longitude',
    headerName: 'Longitude',
    width: 100,
    hide: true,
    renderCell,
  },
  {
    field: 'isFavorite',
    headerName: '',
    renderCell: renderFavoriteEditCell,
    headerAlign: 'center',
    width: 80,
    hide: !showColumn,
  },
  {
    field: 'Rig',
    headerName: 'Rigs',
    width: 300,
    renderCell: (params) => (
      <Tooltip title={params.value}>
        <div style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}>
          {params.value}
        </div>
      </Tooltip>
    ),
    hide: false,
    headerAlign: 'center',
    editable: dropdownEditable,
    renderEditCell: renderJobsRigEditCell,
    align: 'center',
  },
  {
    field: 'Well',
    headerName: 'Wells',
    width: 300,
    renderCell: (params) => (
      <Tooltip title={params.value}>
        <div style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}>
          {params.value}
        </div>
      </Tooltip>
    ),
    hide: false,
    headerAlign: 'center',
    editable: dropdownEditable,
    renderEditCell: renderJobsWellEditCell,
    align: 'center',
  },
  {
    field: 'assets',
    headerName: 'Assets',
    renderCell: renderAssetsCell,
    headerAlign: 'center',
    hide: !showColumn,
    width: 80,
  },
  {
    field: 'data',
    headerName: 'Data',
    renderCell: renderDataCell,
    headerAlign: 'center',
    hide: !showColumn,
    width: 150,
  },
  {
    field: 'report',
    headerName: 'Reports',
    renderCell: renderReportsCell,
    headerAlign: 'center',
    hide: !showColumn,
  },
  {
    field: 'systemStatus',
    headerName: 'Status',
    renderCell: renderStatusCell,
    headerAlign: 'center',
    hide: false,
  },
  {
    field: 'alarm',
    headerName: 'Alarms',
    renderCell: renderAlarmsCell,
    headerAlign: 'center',
    hide: false,
  },
  {
    field: 'hydraulic',
    headerName: 'Hydraulics',
    renderCell: renderHydraulicCell,
    headerAlign: 'center',
    hide: !showColumn,
    width: 100,
    align: 'center',
  },
  {
    field: 'latestTimestamp',
    headerName: 'Last Update',
    width: 200,
    renderCell,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'truFlow',
    headerName: 'Tru-Flow',
    width: 200,
    renderCell: renderTruFlowCell,
    headerAlign: 'center',
    align: 'center',
    // hide: !showColumn,
    hide: true
  },
  {
    field: 'usage',
    headerName: 'Usage',
    width: 150,
    renderCell,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'remainingLife',
    headerName: 'Remaining Life ',
    width: 110,
    renderCell,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 100,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
    hide: !showColumn,
  },
];

export default columns;
